import {Controller} from 'stimulus'

export default class extends Controller {

    static  targets = ['resourceSelect', 'categorySelect', 'contentSelect']

    connect() {

    }

    getCategories(_event) {
        let data = {resource_id: this.resourceSelectTarget.value}
        this.populateContents(this.categorySelectTarget, "/admin/categories/resource_categories", data, this.getContents.bind(this))
    }

    getContents(_event) {
        if (this.categorySelectTarget.value !== "") {
            let data = {category_id: this.categorySelectTarget.value}
            this.populateContents(this.contentSelectTarget, "/admin/contents/category_contents", data, () => {
            })
        } else {
            $(this.contentSelectTarget).empty()
        }
    }

    populateContents(setInput, url, data, callback) {
        $.ajax({
            type: "GET",
            contentType: "application/json; charset=utf-8",
            url: url,
            data: data,
            dataType: "json",
            success: (result) => {
                $(setInput).empty()
                for (let i = 0; i < result.length; i++) {
                    let option = document.createElement("option")
                    option.text = result[i].title
                    option.value = result[i].id
                    setInput.appendChild(option)
                }
                callback()
            }
        })
    }
}