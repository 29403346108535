import {Controller} from 'stimulus'

export default class extends Controller {

    static  targets = ['filterCheck', 'filterBadge']

    connect() {
        $(this.element).on('submit', this.showLoader.bind(this))
        this.toggleFilterBadge()
    }

    getSearchedCategories(event) {
        this.element.submit()
        this.showLoader()
    }

    toggleFilterBadge() {
        let count = this.filterCheckTargets.filter(elm => elm.checked).length
        if (count > 0) {
            this.filterBadgeTarget.classList.remove('d-none')
            this.filterBadgeTarget.textContent = count
        } else {
            this.filterBadgeTarget.classList.add('d-none')
        }
    }

    showLoader() {
        document.getElementById("searchLoader").classList.remove('d-none')
    }

}