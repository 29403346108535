import {Controller} from "stimulus"
import Quill from "quill";
import ImageResize from 'quill-image-resize-module'

export default class extends Controller {
    static  targets = ["resourceSelect", "categorySelect", "contentType", "articleField", "fileField", "videoField", "articleInput"]

    initialize() {
        Quill.register('modules/ImageResize', ImageResize)
    }

    connect() {
        let content = document.querySelector('[data-aa-quill-content]')
        this.editor = new Quill(content, this.quillOptions)
        this.articleFieldTarget.classList.add('d-none')
        this.videoFieldTarget.classList.add('d-none')
        this.fileFieldTarget.classList.add('d-none')
        this.showFields()
    }

    submit(event) {
        if (this.editor.editor.isBlank()) {
            this.articleInputTarget.value = ""
        } else {
            this.articleInputTarget.value = this.editor.root.innerHTML
        }
    }

    getCategories(_event) {
        $.ajax({
            type: "GET",
            contentType: "application/json; charset=utf-8",
            url: "/admin/categories/resource_categories",
            data: {resource_id: this.resourceSelectTarget.value},
            dataType: "json",
            success: (result) => {
                $(this.categorySelectTarget).empty()
                for (let i = 0; i < result.length; i++) {
                    let categoryOption = document.createElement("option")
                    categoryOption.text = result[i].title
                    categoryOption.value = result[i].id
                    this.categorySelectTarget.appendChild(categoryOption)
                }
            }
        })
    }

    showFields(_event) {
        var content_type = this.contentTypeTarget.value
        if (content_type === 'article') {
            this.showSelectedField(this.articleFieldTarget, this.videoFieldTarget, this.fileFieldTarget)
        } else if (content_type === 'video') {
            this.showSelectedField(this.videoFieldTarget, this.articleFieldTarget, this.fileFieldTarget)
        } else if (content_type === 'image' || content_type === 'pdf') {
            this.showSelectedField(this.fileFieldTarget, this.articleFieldTarget, this.videoFieldTarget)
        }
    }

    showSelectedField(showField, hideField1, hideField2) {
        showField.classList.remove('d-none')
        hideField1.classList.add('d-none')
        hideField2.classList.add('d-none')
    }

    get quillOptions() {
        return {
            modules: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'align': []}],
                    ['link'],
                    [{'size': ['small', false, 'large', 'huge']}],
                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                    [{'indent': '-1'}, {'indent': '+1'}],
                    [{'direction': 'rtl'}],
                    [{'color': []}, {'background': []}],
                    [{'font': []}],
                    ['clean'],
                    ['image'],
                    ['video']
                ],
                ImageResize: {}
            },
            theme: 'snow'
        }
    }
}
